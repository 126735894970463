import React from 'react';
import './Cards.css';
import CardLink from './CardLink';

function BarcelonaCards() {
  return (
    <div className='cards'>
      <h1>
        Sobre Barcelona
      </h1>
      <p>
        Barcelona es una ciudad con mucho que ofrecer. Conocida por su rica historia, arquitectura única y cultura diversa. Destacan obras maestras de Antoni Gaudí, como la Sagrada Familia y el Parque Güell, que reflejan el modernismo catalán. La ciudad también cuenta con hermosas playas, amplias avenidas como La Rambla, y un animado ambiente artístico y gastronómico. Con su mezcla de tradición y modernidad, Barcelona ofrece una experiencia inolvidable pero tiene sus complicaciones.<br />
        Les compartimos algunos sitios de interés, con sus páginas oficiales -  ya que en otros sitios pueden subirles el precio.
      </p>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardLink
              src='images/Metro.jpg'
              text='Transporte Publico - Metro y Bus'
              label='Movilidad'
              description='Se pueden comprar tarjetas con viajes en cada estación de metro y son válidas para bus también.'
              url='https://www.tmb.cat/es/visita-barcelona/barcelona-en-transporte-publico/moverse'
              />
              <CardLink
              src='images/Taxi.jpeg'
              text='Transporte Privado - Taxi'
              label='Movilidad'
              description='Siempre asegúrate que el conductor active el taxímetro.'
              url='https://www.free-now.com/es/viaja/taxi-barcelona/'
              />
              <CardLink
              src='images/Cabify.jpg'
              text='Transporte Privado - Cabify'
              description='Cabify es una app similar al Uber.'
              label='Movilidad'
              url='https://cabify.com/es'
              />
        </ul>
        <ul className='cards__items'>
           <CardLink
              src='images/eSims.png'
              text='eSims - Airalo y HolaFly'
              label='Conexión'
              description='Son Apps que permiten conexión a internet en el extranjero sin necesidad de cambiar el sim. Depende del modelo de movil.'
              url='https://www.airalo.com/es-ES'
             /> 
             <CardLink
              src='images/vodafone.png'
              text='Sims Prepago - Orange y Vodafone'
              label='Conexión'
              description='Se pueden comprar directamente con pasaporte en el aeropuerto o cualquiera de sus sucursales.'
              url='https://www.vodafone.es/c/particulares/es/productos-y-servicios/movil/prepago-y-recargas/tarifas-de-prepago/'
             /> 
            <CardLink
              src='images/SagradaFamilia.jpg'
              text='Consejos de Seguridad'
              label='Seguridad'
              description='Tips de seguridad para tomar en cuenta al ir por el centro o en transporte público.'
              url='https://barcelonalocalexperiences.com/es/seguridad-barcelona-guia-viajeros/'
            />
        </ul>
        
        <ul className='cards__items'>
            <CardLink
              src='images/MuseoArte.jpg'
              text='Museo Nacional de Arte de Catalunya'
              label='Que visitar'
              description='En la zona de Montjuic. El paseo hacia el museo es precioso. De hecho, ahí nos hicimos las fotos de esta web.'
              url='https://www.museunacional.cat/es'
            />
            <CardLink
              src='images/CasaBatllo.jpg'
              text='Casa Batlló'
              label='Que visitar'
              description='En Passeig de Gràcia. En toda esta calle encontrarán además muchas tiendas para hacer compras.'
              url='https://www.casabatllo.es/venta-entradas/'
            />
            <CardLink
              src='images/Sagrada2.jpg'
              text='Sagrada Familia'
              label='Que visitar'
              description="En L'Eixample. Es la joya de Barcelona. Comprar entradas."
              url='https://www.sagradafamilia-tickets.org'
            />
            <CardLink
              src='images/Barca.jpg'
              text='Museo del Futbol Club Barcelona'
              label='Que visitar'
              description='Camp Nou esta cerrado de momento, pero igual hay un tour interesante.'
              url='https://www.fcbarcelona.es/es/entradas/barca-immersive-tour'
            />
          </ul>
        </div>
      </div>
    </div>
  )
}

export default BarcelonaCards